import { twMerge } from "tailwind-merge";
import type { loader as rootLoader } from "~/root";
import { Link, useRouteLoaderData } from "@remix-run/react";
import type { CategoryCardType } from "~/graphql/fragments/types";
import Image from "./Image";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  categories?: CategoryCardType[];
}

const categoryPositions = {
  "solar-panels": "md:(right-[65%] bottom-[52%])",
  "mini-panels": "md:(left-[57%] top-[43%])",
  "home-solar-panels": "md:(left-[43%] top-[58%])",
  "camping-solar-panels": "md:(left-[65%] bottom-[17%])",
  "home-solar-batteries": "md:(right-[63%] top-[63%])",
  "solar-batteries": "md:(left-[40%] top-[72%])",
};

const Hero: React.FC<Props> = ({ categories, className, ...props }) => {
  const { heroImage } =
    useRouteLoaderData<typeof rootLoader>("root")?.site ?? {};
  return (
    heroImage && (
      <div
        {...props}
        className={twMerge(
          "relative flex w-full sm:aspect-square md:aspect-[21/9] md:max-h-[max(40rem,45vh)] md:overflow-hidden",
          className,
        )}
      >
        <div className="max-md:bottom-0 md:absolute md:top-[75%] md:-translate-y-[75%]">
          <div className="relative">
            <Image
              // @ts-ignore JsonifyObject stuff
              media={heroImage}
              className="w-screen object-cover object-center sm:aspect-square md:aspect-[21/9]"
              fetchPriority="high" // important for LCP metric (Largest Contentful Paint)
              srcSet={[
                {
                  options: { width: 3840 },
                  size: "3840w",
                },
                {
                  options: { width: 2560 },
                  size: "2560w",
                },
                {
                  options: { width: 1920 },
                  size: "1920w",
                },
                {
                  options: { width: 1280 },
                  size: "1280w",
                },
                {
                  options: { width: 880 },
                  size: "880w",
                },
                {
                  options: { width: 500 },
                  size: "500w",
                },
              ]}
            />
            <div className="relative bottom-4 left-0 flex w-full flex-wrap items-end justify-center gap-2 px-4 sm:absolute md:bottom-0 md:h-full">
              {categories?.map((category, index) => {
                if (!category.slug || !(category.slug in categoryPositions)) {
                  return null;
                }
                const position =
                  categoryPositions[
                    category.slug as keyof typeof categoryPositions
                  ];

                return (
                  <div
                    key={index}
                    className={twMerge(
                      "text-blu-500 border-yello-500 text-md flex items-center rounded-[1em] border-4 bg-white px-2 font-bold opacity-95 shadow-lg transition-transform hover:scale-105 hover:opacity-100 md:absolute xl:text-lg",
                      position,
                    )}
                  >
                    <Link key={index} to={category.url as string} className="">
                      {category.name}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default Hero;
